import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Modal, Button, Table, Form, Spinner } from "react-bootstrap";

const PurchaseOrdersPage = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [poForm, setPoForm] = useState({
    vendorId: "",
    poDate: new Date().toISOString().split("T")[0],
    shippingCost: 0,
    taxAmount: 0,
    items: [],
  });

  useEffect(() => {
    fetchPurchaseOrders();
    fetchVendors();
  }, []);

  // Fetch PO List
  const fetchPurchaseOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/purchaseOrders");
      setPurchaseOrders(response.data);
    } catch (error) {
      console.error("Error fetching POs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Vendor List
  const fetchVendors = async () => {
    try {
      const response = await axios.get("/partners?vendor=true");
      setVendors(response.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  // Open Modal for New/Edit PO
  const handleShowModal = (po = null) => {
    setIsEditing(!!po);
    setSelectedPO(po);
    setPoForm(
      po || {
        vendorId: vendors.length > 0 ? vendors[0].id : "",
        poDate: new Date().toISOString().split("T")[0],
        shippingCost: 0,
        taxAmount: 0,
        items: [],
      }
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPoForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...poForm.items];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setPoForm((prev) => ({ ...prev, items: updatedItems }));
  };

  const addLineItem = () => {
    setPoForm((prev) => ({
      ...prev,
      items: [...prev.items, { productId: "", quantity: 1, unitCost: 0, serialNumber: "" }],
    }));
  };

  const removeLineItem = (index) => {
    const updatedItems = [...poForm.items];
    updatedItems.splice(index, 1);
    setPoForm((prev) => ({ ...prev, items: updatedItems }));
  };

  const handleSubmit = async () => {
    try {
      if (!poForm.vendorId) {
        alert("Please select a vendor.");
        return;
      }

      if (isEditing) {
        await axios.put(`/purchaseOrders/${selectedPO.id}`, poForm);
      } else {
        await axios.post("/purchaseOrders", poForm);
      }
      fetchPurchaseOrders();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving PO:", error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Purchase Orders</h2>
      <Button variant="primary" onClick={() => handleShowModal()}>
        + Add New PO
      </Button>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>ID</th>
              <th>Vendor</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders.map((po) => (
              <tr key={po.id}>
                <td>{po.id}</td>
                <td>{po.vendorName || "Unknown"}</td>
                <td>{po.poDate || "Invalid Date"}</td>
                <td>{po.status}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleShowModal(po)}>
                    View / Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for New/Edit PO */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Purchase Order" : "New Purchase Order"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h5>PO Header</h5>
            <Form.Group>
              <Form.Label>Vendor</Form.Label>
              <Form.Control as="select" name="vendorId" value={poForm.vendorId} onChange={handleChange}>
                {vendors.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>PO Date</Form.Label>
              <Form.Control type="date" name="poDate" value={poForm.poDate} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Shipping Cost</Form.Label>
              <Form.Control type="number" name="shippingCost" value={poForm.shippingCost} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tax Amount</Form.Label>
              <Form.Control type="number" name="taxAmount" value={poForm.taxAmount} onChange={handleChange} />
            </Form.Group>

            <h5 className="mt-3">Line Items</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>Quantity</th>
                  <th>Unit Cost</th>
                  <th>Serial Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {poForm.items.map((item, index) => (
                  <tr key={index}>
                    <td><Form.Control type="text" name="productId" value={item.productId} onChange={(e) => handleItemChange(index, e)} /></td>
                    <td><Form.Control type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(index, e)} /></td>
                    <td><Form.Control type="number" name="unitCost" value={item.unitCost} onChange={(e) => handleItemChange(index, e)} /></td>
                    <td><Form.Control type="text" name="serialNumber" value={item.serialNumber} onChange={(e) => handleItemChange(index, e)} /></td>
                    <td><Button variant="danger" size="sm" onClick={() => removeLineItem(index)}>Remove</Button></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="secondary" onClick={addLineItem}>+ Add Line Item</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>{isEditing ? "Update PO" : "Create PO"}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PurchaseOrdersPage;
