import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setUser(token ? { token } : null);  // Ensure state updates correctly
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    setUser({ token }); // Immediately update UI
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null); // Immediately update UI
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
